<template>
    <div class="cMain">
        <div class="cOne">
            <img src="../../assets/cduan/cduan-1.jpg" width="100%">
        </div>
        <div class="cTwo">

            <h1>中广云分期APP<br />电站管理一体</h1>
            <p>工商银行光伏贷<br />方便简单 效率快捷 额度大<br />电站管理 方便操作 收益看得见</p>
            <div style="width:50%;font-size: 15px;margin: 0 auto;">
                <p style="width:130px;height: 25px;background-color: rgb(247, 137, 137);margin: 0 auto;border-radius: 10px;
                color: #FFF;line-height:5px;" @mouseover="sidseover1" @mouseleave="sidleave1">点击下载
                </p>
                <div id="xiazai">
                    <img src="../../assets/cduan/erweima.jpg" width="90%">
                </div>
            </div>
            <div class="xiangTwo">
                <span>应用名称：中广云分期 | 应用版本: 1.23 beta版<br />
                    更新日期：2022-08-27T06:39:14.000+0000 |<br />
                    应用详情：<span class="xiangSpan1" id="xiangspan" @click="guan">详情</span> | 使用规范：<span class="xiangSpan2" id="xiangspan" @click="guifan">详情</span> |
                    服务条例：<span class="xiangSpan3" id="xiangspan" @click="fuwu">详情</span> | 隐私保护指引：<span class="xiangSpan4" id="xiangspan" @click="ysi">详情</span> |<br />
                    开发者：陕西中广金融科技有限公司</span>
            </div>
        </div>
        <div>
            <img src="../../assets/cduan/cduan-2.jpg" width="100%">
            <img src="../../assets/cduan/cduan-3.jpg" width="100%">
            <img src="../../assets/cduan/cduan-4.jpg" width="100%">
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        xiangqing() {
            this.$router.push("/xiangqing");
        },
        guan() {
            this.$router.push("/guan");
        },
        fuwu() {
            this.$router.push("/fuwu");
        },
        guifan() {
            this.$router.push("/guifan");
        },
        ysi() {
            this.$router.push("/ysi");
        },
        sidseover1() {
            let xiazai = document.getElementById('xiazai')
            xiazai.style.display = 'block'
        },
        sidleave1() {
            let xiazai = document.getElementById('xiazai')
            xiazai.style.display = 'none'
        },
    }
}
</script>

<style scoped>
ul li {
    list-style: none;
}

.cMain {
    width: 100%;
    height: 100%;
    position: relative;
}

.cTwo {
    width: 30%;
    height: 600px;
    position: absolute;
    top: 250px;
    right: 250px;
    text-align: center;
}

.cTwo h1 {
    font-size: 30px;
    color: #09c7bd;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.cTwo p {
    font-size: 18px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgb(64, 63, 63);
    line-height: 30px;
    padding-top: 20px;
}

.xiangTwo {
    width: 100%;
    margin: 20px auto;
    position: absolute;
    left: 10px;
}

.xiangTwo span {
    font-size: 10px;
    line-height: 15px;
    color: rgb(71, 71, 70);
}

#xiazai {
    width: 160px;
    height: 150px;
    background-color: #fff;
    display: none;
    position: absolute;
    top: 310px;
    right: 200px;
    z-index: 999;
}
#xiangspan{
    color: red;
}
.xiangSpan1:hover{
    text-decoration:underline;
    cursor: pointer;
}
.xiangSpan2:hover{
    text-decoration:underline;
    cursor: pointer;
}
.xiangSpan3:hover{
    text-decoration:underline;
    cursor: pointer;
}
.xiangSpan4:hover{
    text-decoration:underline;
    cursor: pointer;
}
</style>