<template>
    <div style="width: 100%; position: absolute; left: 0;">
        <div class="nav-two">
            <el-row>
                <el-col :span="8" style="margin-left: 20%;">
                    <h4 style="padding-top: 30px; font-size: 15px;">CONTACT US<br />联系我们</h4>
                    <div style="padding-top: 55px;font-size: 15px;">
                        <i class="el-icon-location-outline" style="font-size: 15px; padding-right: 5px;"></i>
                        <span>地址 ADRESS</span>
                        <p style="padding-left: 20px;">陕西省西安市经济开发区风城十一路与文景路交口文景广场A座1505室</p>
                    </div>
                    <div style="padding-top: 35px;font-size: 15px;">
                        <i class="el-icon-message" style="font-size: 15px; padding-right: 5px;"></i>
                        <span>邮箱 E-MAIL</span>
                        <p style="padding-left: 20px;">wsgglp@163.com</p>
                    </div>
                    <div style="padding-top: 35px;font-size: 15px;">
                        <i class="el-icon-phone" style="font-size: 15px; padding-right: 5px;"></i>
                        <span>电话 TELPHONE</span>
                        <p style="padding-left: 20px;">029-87940198</p>
                    </div>
                </el-col>
                <el-col :offset="2" :span="8">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm">
                        <h4 style="padding: 20px 0 ;font-size: 18px;">发表您的留言：</h4>
                        <el-form-item label="姓名：" prop="name">
                            <el-input v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱：" prop="email">
                            <el-input v-model="ruleForm.email"></el-input>
                        </el-form-item>
                        <el-form-item label="电话：" prop="phone">
                            <el-input v-model="ruleForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="活动形式:" prop="desc">
                            <el-input type="textarea" v-model="ruleForm.desc"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('ruleForm')" style="line-height: 0;">提交留言
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
        <div class="nav-three">
            <span>©2022 - 陕西中广金融科技有限公司 | 陕ICP备2022008473号</span>
        </div>
        <div style="line-height: 50px;text-align: center;">
            <img src="../assets/gongantubiao.png" alt="">
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61019102000354"
                style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src=""
                    style="float:left;" />
                <p
                    style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 05px; color:#939393;font-size: 15px;">
                    陕公网安备
                    61019102000354号</p>
            </a>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            ruleForm: {
                name: "",
                email: "",
                phone: "",
                desc: ""
            }
        }
    },
    methods: {
    }
}
</script>

<style scoped>
@import './goBtn.css';
* {
    margin: 0;
    padding: 0;
}


.nav-two {
    background-color: rgb(52, 61, 68);
    color: #FFF;
    padding-bottom: 20px;
}

.el-col-8 {
    height: 320px;
    margin-top: 20px;
}

.nav-three {
    height: 40px;
    background-color: rgb(40, 40, 40);
    color: #FFF;
    text-align: center;
    line-height: 40px;
    font-size: 15px;
}

.nav-three span {
    font-size: 12px;
}
</style>