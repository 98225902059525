<template>
    <div class="guanfanMain">
        <div class="guanfanOne">
            <h1>App使用规范</h1>
            <div style="width: 100%;height: 1px;background-color: rgb(182, 181, 181);margin-bottom: 20px;"></div>
            <p>陕西中广金融科技有限公司(以下简称“中广金融”)在此特别提醒您(用户)在注
                册成为用户之前，请认真阅读本《用户协议》(以下简称“协议”)，以确保您
                充分理解本协议中各条款。您的注册、登录、使用等行为将视为对本协议的
                接受，并同意接受本协议各项条款的约束。
                本协议约定中广金融与用户之间关于“中广云分期”软件服务的权利义务。“用户”是指注
                册、登录、使用本服务的个人。本协议可由中广金融随时更新，更新后的协议条
                款一日公布即代替原来的协议条款，怨不再另行通知，用户可在本网站查阅
                最新版协议条款。在 中广金融修改协议条款后，如果用户不接受修改后的条款。
                请立即停止使用中广金融提供的服务，用户继续使用中广金融提供的服务将被视为接受
                修改后的协议。</p>
            <div class="guanfanone-1">
                <p>1. 账户注册</p>
                <span>
                    (a) 用户在使用本服务前需要注册一个“中广云分期app”帐号“中广云分期app”帐号应当使用手
                    机号码绑定直接登录，请用户使用尚未与“中广云分期app”帐号绑定的手机号码，以及未被
                    XX根据本协议封禁的手机号码注册“中广云分期app”帐号。中广云分期app可以根据用户需求或产
                    品需要对帐号注册和绑定的方式进行变更，而无须事先通知用户。<br />
                    (b) 鉴于“中广云分期app”帐号的绑定注册方式，您同意XX在注册时将使用您提供的
                    手机号码及/或自动提取您的手机号码及自动提取您的手机设备识别码等信
                    息用于注册。<br />
                    (c) 在使用app业务服务时，中广云分期app需要搜集能识别用户身份的个人信息
                    以便中广云分期app可以在必要时联系用户，或为用户提供更好的使用体验。中广云分期app搜集的
                    信息包括但不限于用户的性别、年龄、出生日期、个人说明;中广云分期app 同意对这些
                    信息的使用将受限于第三条用户个人隐私信息保护的约束。
                </span>
            </div>
            <div class="guanfanone-1">
                <p>2. 内容规范</p>
                <span>(a) 本条所述内容是指用户使用本服务过程中所制作、上载、复制、发布、传
                    播的任何内容，包括但不限于帐号头像、名称、用户说明等注册信息及认证
                    资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相
                    关链接页面，以及其他使用帐号或本服务所产生的内容。<br />
                    (b) 用户不得利用“XX”帐号或本服务制作、上载、复制、发布、传播如下
                    法律、法规和政策禁止的内容:<br />
                    <span style="font-size: 15px;display:block;padding-left: 20px;">(1)反对宪法所确定的基本原则的;<br />
                        (2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的;<br />
                        (3)损害国家荣誉和利益的;<br />
                        (4)煽动民族仇恨、民族歧视，破坏民族团结的;<br />
                        (5)破坏国家宗教政策，宣扬邪教和封建迷信的;<br />
                        (6)散布谣言，扰乱社会秩序，破坏社会稳定的;<br />
                        (7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的:<br />
                        (8)侮辱或者诽谤他人，侵害他人合法权益的;<br />
                        (9)不遵守法律法规底线、社会主义制度底线、国家利益底线、公民合法权
                        益底线、社会公共秩序底线、道德风尚底线和信息真实性底线的“七条底线”
                        要求的;<br />
                        (10)含有法律、行政法规禁止的其他内容的信息。</span><br />
                    (c) 用户不得利用“中广云分期app”帐号或本服务制作、上载、复制、发布、传播如下
                    干扰“中广云分期app”正常运营，以及侵犯其他用户或第三方合法权益的内容:
                    <span style="font-size: 15px;display:block;padding-left: 20px;">(1)反对宪法所确定的基本原则的;<br />
                        (1)含有任何性或性暗示的;<br />
                        (2)含有辱骂、恐吓、威胁内容的;<br />
                        (3)含有骚扰、垃圾广告、恶意信息、诱骗信息的;<br />
                        (4)涉及他人隐私、个人信息或资料的;<br />
                        (5)侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的;<br />
                        (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的
                        信息。</span>
                </span>
            </div>
            <div class="guanfanone-1">
                <p>3. 使用规则</p>
                <span>(a) 用户在本服务中或通过本服务所传送、发布的任何内容并不反映或代表，
                    也不得被视为反映或代表中广金融的观点、立场或政策，中广金融对此不承担任何责任<br />
                    (b) 用户须对利用“中广云分期app”帐号或本服务传送信息的真实性、合法性、无害性、
                    准确性、有效性等全权负责，与用户所传播的信息相关的任何法律责任由用
                    户自行承担，与中广金融科技无关。如因此给中广金融或第三方造成损害的，用户应
                    当依法予以赔偿。<br />
                    (c) 用户不得利用“中广云分期app”帐号或本服务进行如下行为:<br />
                    <span style="font-size: 15px;display:block;padding-left: 20px;">(1)反对宪法所确定的基本原则的;<br />
                        (1)提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义的;<br />
                        (2)强制、诱导其他用户关注、点击链接页面或分享信息的;<br />
                        (3)虚构事实、隐瞒真相以误导、欺骗他人的;<br />
                        (4)利用技术手段批量建立虚假账号的;<br />
                        (5)制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运
                        营或传播，无论这些行为是否为商业目的;<br />
                        (6)含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的
                        信息。</span>
                </span>
            </div>
            <div class="guanfanone-1" style="margin-bottom: 30px;">
                <p>4. 法律责任</p>
                <span>
                    (a) 如果中广金融发现或收到他人举报或投诉用户违反本协议约定的，中广金融中广金融有权不
                    经通知随时对相关内容，包括但不限于用户资料、聊天记录进行审查、删除，
                    并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁、设备封禁、
                    功能封禁的处罚，且通知用户处理结果。
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:#FFF");
    },
}
</script>

<style scoped>
.guanfanMain {
    width: 100%;
    height: 100%;
    margin-top: 80px;
}

.guanfanOne {
    width: 80%;
    margin: 0 auto;
}

.guanfanOne h1 {
    font-size: 30px;
    margin-bottom: 30px;
}

.guanfanOne p {
    font-size: 15px;
    color: rgb(52, 52, 52);
}

.guanfanone-1 p {
    font-size: 20px;
    color: rgb(52, 52, 52);
    margin-top: 40px;
}

.guanfanone-1 span {
    font-size: 15px;
    color: rgb(52, 52, 52);
    line-height: 32px;
}
</style>