import Vue from 'vue'
import VueRouter from 'vue-router'

import goTop from '../components/goTop.vue'
import goBtn from '../components/goBtn.vue'
import sidebar from '../components/sidebar.vue'
import home from '../page/home/home.vue'
import cduan from '../page/cduan/cduan.vue'
import xiangqing from '../page/cduan/xiangqing.vue'
import guan from '../page/cduan/tiaoli/guan.vue'
import guifan from '../page/cduan/tiaoli/guifan.vue'
import fuwu from '../page/cduan/tiaoli/fuwu.vue'
import ysi from '../page/cduan/tiaoli/ysi.vue'
import taiyang from '../page/taiyang/taiyuan.vue'
import chanping from '../page/chanping/chanping.vue'
import xinwen from '../page/xinwen/xinwen.vue'
import gunayu from '../page/guanyu/guanyu.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/goTop',
    component: goTop
  },
  {
    path: '/goBtn',
    component: goBtn
  },
  {
    path: '/sidebar',
    component: sidebar
  },
  {
    path: '/',
    component: home
  },
  {
    path: '/cduan',
    component: cduan
  },
  {
    path: '/xiangqing',
    component: xiangqing
  },
  {
    path: '/guan',
    component: guan
  },
  {
    path: '/guifan',
    component: guifan
  },
  {
    path: '/fuwu',
    component: fuwu
  },
  {
    path: '/ysi',
    component: ysi
  },
  {
    path: '/taiyang',
    component: taiyang
  },
  {
    path: '/chanping',
    component: chanping
  },
  {
    path: '/xinwen',
    component: xinwen
  },
  {
    path: '/gunayu',
    component: gunayu
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
