<template>
    <div>
        <div class="homeMain">
            <div class="homeOne">
                <el-row>
                    <el-col :span="10" style="margin-left: 150px">
                        <el-carousel style="height: 400px;">
                            <el-carousel-item v-for="item in imgArray" :key="item" style="height: 400px;">
                                <img :src="item" class="rightImg">
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                    <el-col :span="10" style="margin-left: 25px;">
                        <el-tabs v-model="tabsName" type="card" @tab-click="tabsClick">
                            <el-tab-pane label="户用光伏" name="tabs1">
                                <div class="homeOneTab" v-for="(item, index) in tabs1" :key="index">
                                    <span style="font-size: 15px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="要闻推荐" name="tabs2">
                                <div class="homeOneTab" v-for="(item, index) in tabs2" :key="index">
                                    <span style="font-size: 15px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="政策动态" name="tabs3">
                                <div class="homeOneTab" v-for="(item, index) in tabs3" :key="index">
                                    <span style="font-size: 15px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
            <div class="homeTwo">
                <el-row>
                    <el-col class="homeTwo-1">
                        <div class="homeTop">
                            <img src="../../assets/home/ju.png" alt="">
                            <span>户用光伏资讯排行榜</span>
                            <p></p>
                            <div class="homeTwoTab" v-for="(item, index) in tabs4" :key="index">
                                <img :src="item.imgUrl">
                                <span class="xinTwoSpan">{{ item.title }}</span>
                            </div> -->
                        </div>
                    </el-col>
                    <el-col class="homeTwo-2">
                        <p>户用光伏项目案例</p>
                        <el-carousel style="height: 405px;">
                            <el-carousel-item v-for="item in imgArray2" :key="item" style="height: 403px;">
                                <img :src="item" class="rightImg">
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                    <el-col class="homeTwo-3">
                        <p class="homeTwo-1-p">咨询定制户用光伏系统</p>
                        <div class="homeTwo-mar">
                            <marquee direction=up scrolldelay="10" scrollamount="1" loop="-1" behavior="scroll"
                                hspace="10" vspace="10" onMouseOver="this.stop()" onMouseOut="this.start()" width="100%"
                                height="30px">
                                <p>榆林市 张**客户已安装户用光伏电站！</p>
                                <p>渭南市 孙**客户已安装户用光伏电站！</p>
                                <p>渭南市 余**客户已安装户用光伏电站！</p>
                            </marquee>
                        </div>
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
                            style="width: 75%;margin: 20px auto;">
                            <el-form-item prop="name">
                                <el-input size="mini" v-model="ruleForm.name" placeholder="姓名" style="width: 100%;"></el-input>
                            </el-form-item>
                            <el-form-item prop="phone">
                                <el-input size="mini" v-model="ruleForm.phone" placeholder="联系方式" style="width: 100%;"></el-input>
                            </el-form-item>
                            <el-form-item prop="xuanze">
                                <el-row>
                                    <el-col :span="10">
                                        <el-select v-model="selectProvince" placeholder="陕西省" size="mini"
                                            style="width: 150px">
                                            <el-option v-for="item in provinces" size="small" :key="item.value"
                                                :label="item.label" :value="item.value"
                                                @click.native="changeProvince()">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="10" style="margin-left: 20px;">
                                        <el-select v-model="selectCity" placeholder="请选择市" size="mini"
                                            style="width: 150px">
                                            <el-option v-for="item in cities" size="small" :key="item.value"
                                                :label="item.label" :value="item.value" @click.native="changeCity">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="danger" @click="submitForm('ruleForm')"
                                    style="line-height: 0;width: 100%;">安装咨询
                                </el-button>
                            </el-form-item>
                        </el-form>
                        <p
                            style="width: 75%;height: 0.5px;border-bottom: 0.5px dashed rgb(231, 227, 227);margin: 30px auto;">
                        </p>
                        <p style="width: 100%;font-size: 14px;text-align: center;">
                            在线咨询：
                            <span style="color: rgb(255, 85, 0);">029-87940198</span>
                        </p>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import { regionData } from "../../../node_modules/element-china-area-data";
export default {
    data() {
        return {
            tabsName: 'tabs1',
            tabs1: [
                {
                    title: "截止到2022年7月底全国各省光伏补贴政策汇总。"
                },
                {
                    title: "国家惠民工程光伏项目到农户 为民又增致富路。"
                },
                {
                    title: "国家和地方不断出台政策，鼓励企业和个人采用户用分布式光伏发电。"
                },
                {
                    title: "屋顶光伏发电，自发自用、余电上网，既节约电力也自然环保。"
                },
                {
                    title: "国家发改委 “十四五”推进农业农村现代化规划发布"
                },
                {
                    title: "浙江宁波：新建房屋建筑强制安装分光伏系统"
                },
                {
                    title: "862.60KW！广东江门第六批个人住宅分布"
                },
                {
                    title: "河北：屋顶分布式逐步按照“光伏+储能”方式开发"
                }
            ],
            tabs2: [
                {
                    title: "国家能源局：不得强制产业配套！"
                },
                {
                    title: "财政部：支持黄河流域上游继续推进大型风光基地建设"
                },
                {
                    title: "光伏电站怎么建？国家能源局二次征求稿变化有哪些？"
                },
                {
                    title: "小米开始做光伏了！用的这家的技术 100W首发价1099元"
                },
                {
                    title: "粤水电：拟以76.88亿投建800MW光伏项目及配套储能"
                },
                {
                    title: "天合光能：2022年光伏支架出货量计划增长50%"
                },
                {
                    title: "三峡集团2022年度光伏组件、逆变器集采中标候选人公示"
                },
                {
                    title: "官方解读丨取消内蒙古优惠电价对企业生产经营影响"
                }
            ],
            tabs3: [
                {
                    title: "09-06国家能源局：结合光伏电站发展需要 电网企业应及时优化电网规划建设方案..."
                },
                {
                    title: "09-05国家能源局：征集电力可靠性管理优秀实践案例"
                },
                {
                    title: "09-05广西能源发展“十四五”规划：力争新建110千伏智能化变电站180座 建成高可靠..."
                },
                {
                    title: "广东省进一步促进工业经济平稳增长若干措施：切实保障产业链供应链重点企业电力供应套！"
                },
                {
                    title: "09-02内蒙古：取消蒙西电网战略性新兴产业优惠电价政策、蒙东电网大工业用电.."
                },
                {
                    title: "09-02国务院发布《关于支持山东深化新旧动能转换推动绿色低碳高质量发展的意见》"
                },
                {
                    title: "09-05浙江龙港市电力设施空间布局专项规划(征求意见稿)：本次规划范围内未来将拥..."
                },
                {
                    title: "09-013部门给予18家能源名企新使命！实施储能技术国家"
                }
            ],
            tabs4: [
                {
                    imgUrl: require('../../assets/home/1.png'),
                    title: "分布式光伏，变了！"
                },
                {
                    imgUrl: require('../../assets/home/2.png'),
                    title: "户用光伏榜首易主！"
                },
                {
                    imgUrl: require('../../assets/home/3.png'),
                    title: "辽宁凤城：禁止户用光伏申请全额上网,租用屋顶"
                },
                {
                    imgUrl: require('../../assets/home/4.png'),
                    title: "四次招标、四次废标！"
                },
                {
                    imgUrl: require('../../assets/home/5.png'),
                    title: "湖南发布《分布式光伏发电项目管理暂行办法》"
                },
                {
                    imgUrl: require('../../assets/home/6.png'),
                    title: "国内首个屋顶柔性支架光伏项目并网发电"
                },
                {
                    imgUrl: require('../../assets/home/7.png'),
                    title: "江苏无锡：分布式光伏给予0.1元/瓦一次性补贴"
                },
                {
                    imgUrl: require('../../assets/home/8.png'),
                    title: "安徽亳州市利辛县光伏扶贫电站运维管理办法"
                },
                {
                    imgUrl: require('../../assets/home/9.png'),
                    title: "鼓励户用配储！攀枝花下发户用光伏安装规范"
                },
                {
                    imgUrl: require('../../assets/home/10.png'),
                    title: "862.60KW！广东江门第六批个人住宅分布"
                },
            ],
            ruleForm: {
                name: "",
                phone: "",
            },
            provinces: regionData,
            cities: [],
            area: [],
            selectProvince: "",
            selectCity: "",
            selectArea: "",
            imgArray: [
                require('../../assets/home/carousel-1.jpg'),
                require('../../assets/home/carousel-2.jpg'),
                require('../../assets/home/carousel-3.jpg'),
            ],
            imgArray2: [
                require('../../assets/home/carousel-4.png'),
                require('../../assets/home/carousel-5.png'),
                require('../../assets/home/carousel-6.png'),
            ]

        }
    },
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:rgb(239, 239, 239)");
    },
    methods: {
        xinwen() {
            this.$router.push("/xinwen");
        },
        tabsClick(tab, event) {
            console.log(tab, event);
        },
        submitForm() {
            alert('该功能维护中')
        },
        changeProvince() {
            //console.log(this.provinces)
            //console.log(this.selectProvince)
            this.cities = [];
            this.area = [];
            this.selectCity = "";
            this.selectArea = "";
            let cityItem = this.provinces.filter(
                (item) => item.value === this.selectProvince
            );
            if (cityItem[0]) {
                this.cities = cityItem[0].children;
            }
        },
        changeCity() {
            console.log("城市选择")
            console.log(this.selectCity)
            this.area = [];
            this.selectArea = "";
            let areaItem = this.cities.filter(
                (item) => item.value === this.selectCity
            );
            if (areaItem[0]) {
                this.area = areaItem[0].children;
            }
        },
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.homeMain {
    width: 100%;
}

.homeOne {
    width: 100%;
    height: 320px;
    margin-top: 80px;
}

.homeOne .el-col-10 {
    height: 400px;
    background-color: #FFF;
    border-radius: 5px;
    margin-bottom: 30px;
}

.homeOneTab {
    line-height: 32px;
    margin-left: 18px;
    color: rgb(119, 116, 116);
}

.homeOneTab:nth-child(1) {
    color: red;
}

.homeOneTab>span:nth-child(2) {
    font-size: 18px;
    margin-left: 12px;
}

.homeOneTab>span:hover {
    color: rgb(0, 159, 234);
}

.homeTwo {
    margin: 0 0 40px 150px;
}


.homeTwo-1 {
    width: 21%;
    height: 450px;
    border: 1px solid rgb(196, 196, 196);
    background-color: #FFF;
    border-radius: 5px;
}

.homeTwo-2 {
    width: 49%;
    height: 450px;
    border: 1px solid rgb(196, 196, 196);
    background-color: #FFF;
    margin-left: 25px;
    border-radius: 5px;
    text-align: center;
}
.homeTwo-2 p{
    font-size: 20px;
    padding: 10px 0 10px 0;
    background-color: rgb(255, 85, 0);
    color: #FFF;
}

.homeTwo-3 {
    width: 21%;
    height: 450px;
    border: 5px solid rgb(196, 196, 196);
    background-color: #FFF;
    margin-left: 25px;
    border-radius: 5px;
}

.homeTop {
    padding: 15px 0 0 20px;
}

.homeTop span {
    font-size: 18px;
    margin-left: 10px;
}

.homeTop p {
    width: 220px;
    border: 0.5px solid rgb(231, 227, 227);
    margin: 15px 0 15px 0;
}

.homeTwoTab span {
    font-size: 12px;
}

.homeTwoTab span:hover {
    color: rgb(0, 159, 234);
}

.homeTwoTab {
    line-height: 30px;
}

.homeTwo-1-p {
    width: 100%;
    height: 35px;
    background-color: rgb(255, 85, 0);
    font-size: 18px;
    color: #FFF;
    text-align: center;
    line-height: 35px;
    border-bottom: 1px solid rgb(231, 227, 227);
}

.homeTwo-mar {
    font-size: 9px;
    line-height: 30px;
    margin-top: 20px;
}

.homeTwo-mar p {
    text-align: center;
    color: red;
}

.rightImg {
    width: 100%;
    height: 100%;
}
</style>