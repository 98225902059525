<template>
    <div>
        <div class="xinMain">
            <div class="xinOne">
                <img src="../../assets/xinwei/xinwen-1.jpg" width="100%">
                <div class="title">
                    <p>新闻资讯</p>
                    <span>————— NEWS CENTER —————</span>
                </div>
                <el-row style="padding: 30px 0 30px 25px ;">
                    <el-col :span="10" style="margin-left: 11%;">
                        <img src="../../assets/xinwei/xinwen-2.jpg" alt="">
                        <el-tabs v-model="activeName1" @tab-click="handleClick" style="width: 75%;margin-top: 20px;">
                            <el-tab-pane label="要闻推荐" name="yaowen">
                                <div class="xinOnoTab" style="color: red;">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinOneSpan">三部委：已建风光项目按规管理、严禁扩大规模与范围</span>
                                </div>
                                <div class="xinOnoTab" v-for="(item, index) in xinWen" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinOneSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :span="10">
                        <img src="../../assets/xinwei/xinwen-3.jpg" alt="">
                        <el-tabs v-model="activeName2" @tab-click="handleClick" style="width: 75%;margin-top: 20px;">
                            <el-tab-pane label="市场动态" name="shichang">
                                <div class="xinOnoTab" v-for="(item, index) in shichang" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinOneSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="名企动态" name="mingqi">
                                <div class="xinOnoTab" v-for="(item, index) in mingqi" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinOneSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
            <div class="xinTwo">
                <el-row>
                    <el-col style="margin-left: 11%;" :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 22px;">
                            <el-tab-pane label="行业政策" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs1" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 22px;">
                            <el-tab-pane label="光伏系统工程" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs2" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 22px;">
                            <el-tab-pane label="行业观点" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs3" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
            <div class="xinTwo">
                <el-row>
                    <el-col style="margin-left: 11%;" :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 00.1rem;">
                            <el-tab-pane label="光伏项目" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs4" :key="index">
                                    <span style="font-size: 0.08rem;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 00.1rem;">
                            <el-tab-pane label="光伏电池组件" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs5" :key="index">
                                    <span style="font-size: 0.08rem;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 00.1rem;">
                            <el-tab-pane label="国际行情" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs6" :key="index">
                                    <span style="font-size: 0.08rem;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
            <div class="xinTwo">
                <el-row>
                    <el-col style="margin-left: 11%;" :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 22px;">
                            <el-tab-pane label="分布式光伏" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs7" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 22px;">
                            <el-tab-pane label="光伏逆变器" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs8" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                    <el-col :span="7">
                        <el-tabs v-model="activeName3" @tab-click="handleClick" style="width: 80%;margin-top: 22px;">
                            <el-tab-pane label="光伏设备" name="tabs1">
                                <div class="xinTwoTab" v-for="(item, index) in tabs9" :key="index">
                                    <span style="font-size: 20px;">●</span>
                                    <span class="xinTwoSpan">{{ item.title }}</span>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName1: 'yaowen',
            activeName2: 'shichang',
            activeName3: 'tabs1',
            xinWen: [
                {
                    title: "江西：河道、湖泊、水库禁止风光项目",
                },
                {
                    title: "晶科能源：受四川限电影响 子公司产线降低至保安负荷运营",
                },
                {
                    title: "连城数控董事长李春安因涉嫌内幕交易被立案调查",
                },
                {
                    title: "福建印发“双碳”工作意见：2030年风光总装机20GW！",
                },
                {
                    title: "硅料持续飙升之下 光伏投资少了？装机进度变慢了？",
                },
                {
                    title: "电建新能源获取甘肃古浪1.5GW“源网荷储一体化”项目",
                },
                {
                    title: "光伏进入“T”时代，组件回收迎来千亿级“新蓝海”",
                },
            ],
            shichang: [
                {
                    title: "浙江宁波8月份将推进会议中心屋顶太阳能光伏项目施工"
                },
                {
                    title: "宁夏发改委召开2022年度光伏竞争配置项目推进会"
                },
                {
                    title: "河南：截至7月底太阳能装机19GW 占比16.89%"
                },
                {
                    title: "硅料持续飙升之下 光伏投资少了？装机进度变慢了？"
                },
                {
                    title: "光伏进入“T”时代，组件回收迎来千亿级“新蓝海”"
                },
                {
                    title: "浙江乐清公示2022年第1-11批户用光伏备案项目"
                },
                {
                    title: "国家能源局：1-7月太阳能新增装机37.73GW"
                },
                {
                    title: "王世江：未来供应链需转向分散化和多元化"
                },
            ],
            mingqi: [
                {
                    title: "资不抵债 老牌光伏组件企业宣布破产"
                },
                {
                    title: "赛伍技术2022年H1净利润同比增加113.87%"
                },
                {
                    title: "金刚玻璃:210mm异质结电池片生产能力将达4.8GW/年"
                },
                {
                    title: "(国强兴晟亮相国际盛会】IEC国际标准促进中心成立仪式!"
                },
                {
                    title: "欧晶科技拟募资43037.08万元 用于石英制品、循环利用工业硅等..."
                },
                {
                    title: "屋顶光伏再迎政策助力 迈贝特邀您接收“阳光福利”"
                },
                {
                    title: "不产生实质性影响!隆基回复李春安被立案调查事件"
                },
                {
                    title: "没人没钱没技术却猛投20GW!沐邦高科获上交所问询函"
                }
            ],
            tabs1: [
                {
                    title: '三部门:促进光伏产业链供应链协同发展 避免产业趋'
                },
                {
                    title: '江西:生态修复规模达到10公顷以上可取10%新增建设'
                },
                {
                    title: '河南:新建并网新能源项目要购买一定挂钩比例储能租'
                },
                {
                    title: '天津:鼓励利用近海滩涂区、围而未填海域等区域建设'
                },
                {
                    title: '湖北省电力中长期交易实施细则正式出台'
                },
                {
                    title: '海南:到2025年新增光伏装机4GW'
                },
                {
                    title: '陕西：鼓励建设以消纳新能源为主的增量配电网！'
                }
            ],
            tabs2: [
                {
                    title: '国能(同仁)200MW牧光诸光伏项目EPC总承包公开招'
                },
                {
                    title: '国电电力陕西新能源百万千瓦光伏项目获备案'
                },
                {
                    title: '3.42-3.45元/W!中广核东至县尧渡镇50MW渔光互补光'
                },
                {
                    title: '江苏省扬州江都整区屋顶分布式光伏项目启动'
                },
                {
                    title: '国家电投2021年度企业社会责任报告发布:光伏'
                },
                {
                    title: '支持屋顶分布式光伏开发!贵州首个乡村柔性配网示范'
                },
                {
                    title: '赣州交控集团首个分布式光伏发电项目开工'
                }
            ],
            tabs3: [
                {
                    title: '挺进“A+H”时代 光伏企业扎堆冲刺资本'
                },
                {
                    title: '争夺光伏制造!'
                },
                {
                    title: '光伏进入“T”时代，组件回收迎来千亿级'
                },
                {
                    title: '华电、国家电投11家企业64GW逆变器'
                },
                {
                    title: '五大发电集团7.2GW光伏电站运维定标'
                },
                {
                    title: '分布式光伏，变了!'
                },
                {
                    title: '五大发电集团7.2GW光伏电站运维定标'
                }
            ],
            tabs4: [
                {
                    title: '国电电力陕西新能源百万千瓦光伏项目获备案'
                },
                {
                    title: '江苏省扬州江都整区屋顶分布式光伏项目启动'
                },
                {
                    title: '江西铜鼓县签约光伏设备及元器件项目'
                },
                {
                    title: '广东廉江塘蓬镇3万户户用分布式光伏发电项目启动'
                },
                {
                    title: '一期投资25.68亿元中核时代2GW“源网荷储一体化”项'
                },
                {
                    title: '2.415GW!这七地86个存量风光项目被废止(附名单)'
                },
                {
                    title: '390MW!四川九龙县水光互补项目正式开工'
                }
            ],
            tabs5: [
                {
                    title: '江西铜鼓县签约光伏设备及元器件项目'
                },
                {
                    title: '三部门:促进光伏产业链供应链协同发展 避免产业趋'
                },
                {
                    title: '江西:1-7月太阳能电池产量增长33.1%'
                },
                {
                    title: '山西:2022年1-7月光伏电池产量增长33.3%'
                },
                {
                    title: '正泰新能泰国智能制造基地首片210电池、组件下线'
                },
                {
                    title: '正泰新能ASTRO5双玻组件获“绿色高效双面双玻技术'
                },
                {
                    title: '1.94元/W 通威中标华润电力3GW光伏组件采购'
                }
            ],
            tabs6: [
                {
                    title: '美国预计2030年光伏制造能力将达50GW'
                },
                {
                    title: '梅耶博格与Norwegian Crystals签署硅片供应协议'
                },
                {
                    title: '超30GW!海外“本土化”光伏制造扩产提速'
                },
                {
                    title: '欧洲干旱严重 电力生产受到波及'
                },
                {
                    title: '美国上半年光伏新增装机不到原计划一半，每月延误'
                },
                {
                    title: '美国通过通胀削减法案 光伏市场有望受益'
                },
                {
                    title: '光伏1.8GW，风电1.5GW!西班牙将启动3.3GW可再生'
                }
            ],
            tabs7: [
                {
                    title: '江苏省扬州江都整区屋顶分布式光伏项目启动'
                },
                {
                    title: '赣州交控集团首个分布式光伏发电项目开工'
                },
                {
                    title: '支持屋顶分布式光伏开发!贵州首个乡村柔性配网示范'
                },
                {
                    title: '九州方园:分布式光伏电站业务开展不力 导致上半年营'
                },
                {
                    title: '宁夏发出节约用电倡议书!鼓励安装分布式光伏'
                },
                {
                    title: '赣州稀土友力屋顶分布式光伏项目开工'
                },
                {
                    title: '国内首个屋顶柔性支架光伏项目并网发电'
                }
            ],
            tabs8: [
                {
                    title: '甘肃龙源40MW光伏电站 点亮千年丝绸之路'
                },
                {
                    title: '迈向零碳园区!阳光逆变助力高安建陶基地绿色智造'
                },
                {
                    title: '全方位聚焦安全，固德威“碳”路前行|2022中国光伏绿'
                },
                {
                    title: '科士达增资子公司2.7亿元 支撑新能源业务发展'
                },
                {
                    title: '锦浪科技市值突破1000亿!'
                },
                {
                    title: '德业股份:上半年逆变器销售39.34万台'
                },
                {
                    title: '华润浮山二期100MW复合光伏项目组串式逆变器采购中'
                }
            ],
            tabs9: [
                {
                    title: '金辰股份:聘任闫宝杰为首席技术官 黄永远为营销副总'
                },
                {
                    title: '恒星科技:拟以11亿元投建年产5000万公里金刚线项目'
                },
                {
                    title: '奥特维获天合光能2.6亿元单晶炉设备订单'
                },
                {
                    title: '连城数控董事长李春安因涉嫌内幕交易被立案调查'
                },
                {
                    title: '精功科技2022年H1净利润同比增加43.61%'
                },
                {
                    title: '中钨高新:下属子公司受限电政策影响临时停产'
                },
                {
                    title: '金辰股份首台微晶HJT PECVD量产设备交付发货'
                }
            ],
        }
    },
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:rgb(239, 239, 239)");
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.xinMain {
    width: 100%;
}

.title {
    text-align: center;
    padding-top: 30px;
}

.title p {
    color: rgb(0, 159, 234);
    padding-bottom: 5px;
    font-size: 25px;
}

.title span {
    color: rgb(177, 177, 177);
    font-size: 14px;
}

.xinOneSpan {
    font-size: 14px;
    margin-left: 8px;
    line-height: 30px;
}

.xinOnoTab {
    color: rgb(74, 75, 75);
}

.xinOnoTab:hover {
    color: rgb(11, 93, 177);
}

.xinTwo {
    padding-bottom: 20px;
}

.xinTwoSpan {
    font-size: 12px;
    margin-left: 8px;
    line-height: 25px;
    color: rgb(74, 75, 75);
}

.xinTwoSpan:hover {
    color: rgb(11, 93, 177);
}
</style>