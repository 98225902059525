<template>
    <div class="content">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
            background-color="#545c64" text-color="#fff" active-text-color="#ffd04b" theme="dark" router>
            <el-menu-item><img src="../assets/logo.png" width="75%" /></el-menu-item>
            <el-menu-item index="/">首页</el-menu-item> 
            <el-menu-item index="cduan">客户端下载</el-menu-item>
            <el-menu-item index="taiyang">关于太阳能</el-menu-item>
            <el-menu-item index="chanping">产品&成功案例</el-menu-item>
            <el-menu-item index="xinwen">新闻资讯</el-menu-item>
            <el-menu-item index="gunayu">关于我们</el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: '1',
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    computed: {
        defaultActive: function () {
            return this.$route.path.replace('/', '');
        }
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.content {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}
.el-menu--horizontal>.el-menu-item{
    margin-left: 30px;
}
</style>