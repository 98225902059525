<template>
    <div class="xiangMain">
        <div class="xiangOne" style="padding-top: 20px;">
            <img src="../../assets/cduan/logo.png" width="25%">
            <h2>中广云分期应用详情</h2>
            <p> 中广云分期是陕西中广金融科技于2022年推出的一款通过网络线上APP实现我公司与中国工商银行代理的相关业务进行办理以及户 用光伏板材更替及安装选购的一款依托互联网为载体的软件。
                用户可以通过线上APP更加便利丰富的形式简化业务办理流程及户用电站的数据监测及方便快捷的故障报修服务等。</p>
            <span>产品特点：</span>
            <ul style="margin-top: 15px;">
                <li>1 、光伏、家装、车贷分期实现线上一键提交申请，安全便捷，专业的银行对接服务。</li>
                <li>2 、光伏电站发电实时数据观测分析，收益情况的分析,覆盖主流光伏品牌，精准图表走势、数据分析。</li>
                <li>3 、自营商城提供各种板材耗材零配件销售。</li>
                <li>4 、分期产品到期及时提醒，不逾期，及时获得还款提醒，轻松还款告别逾期。</li>
                <li>5 、专业的故障保修团队，一键即可实现故障报修，精准定位。</li>
                <li>6 、支持iPhone、Android平台</li>
                <li>7 、..........</li>
            </ul>
        </div>
        <div class="xiangTwo">
            <span>应用名称：中广云分期 | 应用版本: 1.23 beta版</span><br/>
            <span>更新日期：2022-08-27T06:39:14.000+0000 | 应用</span><br />
            <span>权限：授权 | 隐私政策：授权</span><br />
            <span>开发者：陕西中广金融科技有限公司</span>
        </div>
    </div>
</template>

<script>
export default {
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:rgb(239, 239, 239)");
    },
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.cMain {
    width: 100%;
}
ul li {
    list-style-type: none;
}
.xiangOne {
    width: 20%;
    margin: 40px auto;
}
.xiangTwo{
    width: 20%;
    margin: 20px auto;
    text-align: center;
}

.xiangTwo span{
    font-size: 8px;
    line-height: 15px;
    color: rgb(71, 71, 70);
}
.xiangOne img {
    display: block;
    margin: 0 auto;
}

.xiangOne h2 {
    text-align: center;
    font-size: 18px;
    color: rgb(69, 68, 67);
}

.xiangOne p {
    font-size: 15px;
    text-indent: 30px;
    color: rgb(38, 38, 37);
    line-height: 22px;
    margin-top: 15px;
    font-family: inherit;
    padding-bottom: 15px;
}

.xiangOne span {
    font-size: 15px;
    font-family: inherit;
    color: rgb(71, 71, 70);
}
.xiangOne ul li{
    font-size: 15px;
    color: rgb(71, 71, 70);
    font-family: inherit;
    line-height: 25px;
}
</style>