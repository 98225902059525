<template>
    <div>
        <div class="chanMain">
            <div class="chanOne">
                <img src="../../assets/chanping/chanping.jpg" width="100%">
                <h3>可靠、智能化光伏解决方案</h3>
            </div>
            <div class="chanTwo">
                <div class="title">
                    <p>产品展示</p>
                    <span>————— PRODUCTS —————</span>
                </div>
                <el-row class="chanTwo-1">
                    <el-col :span="10" style="margin-left: 5%;">
                        <img src="../../assets/chanping/chanping2.png" width="70%">
                    </el-col>
                    <el-col :span="8" style="padding-top: 50px;">
                        <h1>PERC 双核组件</h1>
                        <p>环保，不止一面</p>
                        <span>PERC双核组件融合了PERC单晶双面发电技术和成熟的双玻技<br />术，可有效提高系统发电量，根据不同的应用场景，背面发电提<br />升可达25%</span><br />
                        <el-button type="danger">了解更多</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="chanThree">
                <el-row>
                    <el-col :span="8" style="margin-left: 17%;">
                        <img src="../../assets/chanping/chanping3.png" alt="">
                        <h3>常规组件</h3>
                        <span>60-电池片</span><br />
                        <el-button type="danger">了解更多</el-button>
                    </el-col>
                    <el-col :span="8">
                        <img src="../../assets/chanping/chanping4.png" alt="">
                        <h3>大尺寸组件</h3>
                        <span>72-电池片</span><br />
                        <el-button type="danger">了解更多</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="chanFour">
                <el-row>
                    <el-col :span="8" style="margin-left: 17%;">
                        <img src="../../assets/chanping/chanping5.png" alt="">
                        <h3 style="font-size: 20px;padding-bottom: 10px;">第二代多晶光伏发电系统</h3>
                        <span style="color: rgb(103, 101, 101);
    font-size: 15px;">更高标准，更好原装；开启原装家用光伏2.0时代</span><br />
                        <el-button type="danger">了解更多</el-button>
                    </el-col>
                    <el-col :span="8">
                        <img src="../../assets/chanping/chanping6.png" alt="">
                        <h3 style="font-size: 20px;padding-bottom: 10px;">第二代多晶光伏发电系统</h3>
                        <span style="color: rgb(103, 101, 101);
    font-size: 15px;">更高标准，更好原装；开启原装家用光伏2.0时代</span><br />
                        <el-button type="danger">了解更多</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="chanFive">
                <el-row class="chanTwo-1">
                    <el-col :span="10" style="margin-left: 4%;">
                        <img src="../../assets/chanping/chanping7.png">
                    </el-col>
                    <el-col :span="8" style="padding-top: 55px">
                        <h1>智能解决方案</h1>
                        <p>智能优化，更大化发电收益</p>
                        <span>通过电池串级直流优化方案消除子串间电流失配，减少阴影遮挡损<br />失，获得更多发电量，从而更大化您的发电收益。智能化监控，抑<br />制热斑效应，提升组件的长期可靠性</span><br />
                        <el-button type="danger">了解更多</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="chanSix">
                <div class="title">
                    <p>产品展示</p>
                    <span>————— PRODUCTS —————</span>
                </div>
                <el-row class="six-1">
                    <el-col :span="6" style="margin-left: 13%;">
                        <img src="../../assets/chanping/chanping8.jpg" width="450px"><br />
                        <span>光伏案例展示</span>
                    </el-col>
                    <el-col :span="6">
                        <img src="../../assets/chanping/chanping8.jpg" width="450px"><br />
                        <span>光伏案例展示</span>
                    </el-col>
                    <el-col :span="6">
                        <img src="../../assets/chanping/chanping8.jpg" width="450px"><br />
                        <span>光伏案例展示</span>
                    </el-col>
                </el-row>
                <el-row class="six-1">
                    <el-col :span="6" style="margin-left: 13%;">
                        <img src="../../assets/chanping/chanping8.jpg" width="450px"><br />
                        <span>光伏案例展示</span>
                    </el-col>
                    <el-col :span="6">
                        <img src="../../assets/chanping/chanping8.jpg" width="450px"><br />
                        <span>光伏案例展示</span>
                    </el-col>
                    <el-col :span="6">
                        <img src="../../assets/chanping/chanping8.jpg" width="450px"><br />
                        <span>光伏案例展示</span>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:rgb(239, 239, 239)");
    },
}
</script>

<style scoped>
* {
    padding: 0;
    margin: 0;
}

.chanMain {
    width: 100%;
}

.title {
    text-align: center;
    padding-top: 30px;
}

.title p {
    color: rgb(0, 159, 234);
    padding-bottom: 5px;
    font-size: 30px;
}

.title span {
    color: rgb(177, 177, 177);
    font-size: 10px;
}

.chanOne h3 {
    font-size: 40px;
    color: #fff;
    position: absolute;
    top: 150px;
    left: 38%;
}


.chanTwo-1 {
    text-align: right;
    padding-top: 60px;
}

.chanTwo-1 .el-col-8 h1 {
    font-size: 25px;
    padding-bottom: 10px;
}

.chanTwo-1 .el-col-8 p {
    font-size: 20px;
    padding-bottom: 10px;
}

.chanTwo-1 .el-col-8 span {
    font-size: 10px;
    color: rgb(103, 101, 101);
    line-height: 20px;
}

.el-button {
    width: 120px;
    height: 40px;
    margin-top: 10px;
}

.chanThree {
    background-color: #fff;
    text-align: center;
    padding-bottom: 50px;
}

.chanThree h3 {
    font-size: 17px;
}

.chanThree span {
    color: rgb(103, 101, 101);
    font-size: 12px;
}

.chanFour {
    text-align: center;
    padding-bottom: 40px;
}

.chanFive {
    background-color: #fff;
    padding-bottom: 40px;
}

.chanSix {
    text-align: center;
    padding-bottom: 40px;
}

.six-1 {
    padding: 30px 10px 0 0;
}
.six-1 .el-col-6{
    margin-left: 25px;
}
.six-1 span {
    font-size: 13px;
    line-height: 35px;
}
</style>