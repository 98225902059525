<template>
    <div>
        <div class="taiMain">
            <div class="taiOne">
                <img src="../../assets/taiyang/chanping.jpg" width="100%">
                <h1>关于太阳能</h1>
            </div>
            <div class="taiTwo">
                <p>作为一种安全、清洁的能源，太阳能将太阳光有效转化为电能，并应用于生产生活</p>
                <el-row>
                    <el-col :span="6" style="margin-left: 13%;">
                        <img src="../../assets/taiyang/icon1.png" alt=""><br />
                        <span>可再生</span>
                    </el-col>
                    <el-col :span="6">
                        <img src="../../assets/taiyang/icon2.png" alt=""><br />
                        <span>高性价比</span>
                    </el-col>
                    <el-col :span="6">
                        <img src="../../assets/taiyang/icon3.png" alt=""><br />
                        <span>绿色环保</span>
                    </el-col>
                </el-row>
            </div>
            <div class="taiThree">
                <img src="../../assets/taiyang/chanping2.jpg" width="100%" />
                <div class="tauThree-1">
                    <h3>为什么选择太阳能</h3>
                    <span>太阳能易获取，且具有可持续性。太阳能解决方案可降低度电成本，<br />提高房屋价值，减少碳足迹，并避免能源成本的持续增长</span><br />
                    <el-button @click="chanping()" type="danger">了解更多</el-button>
                </div>
            </div>
            <div class="taiThree">
                <img src="../../assets/taiyang/chanping3.jpg" width="100%" />
                <div class="tauThree-2">
                    <h3>分布式光伏发电适用场合</h3>
                    <span>可安装在任何有阳光照射的地方，主要分为两大类：<br />
                        一、全国各类建筑物和公共设施上，形成分布式建筑光伏发电系统。<br />
                        二、 偏远农牧区、海岛等少电无电地区，形成离网型分布式光伏发电系统或微电网。<br />
                        具体地点如工业厂房、商业建筑、农业设施、市政公共建筑、民用建筑等满足荷载要<br />求的混凝土、彩钢板和瓦片式屋顶以及边远农牧区、海岛等少电无电地区。</span><br />
                    <el-button @click="chanping()" type="danger">了解更多</el-button>
                </div>
            </div>
            <div class="taiThree">
                <img src="../../assets/taiyang/chanping4.jpg" width="100%" />
                <div class="tauThree-3">
                    <h3>环境优化</h3>
                    <span>了解太阳能如何通过减少温室气体排放和人类对化石燃<br />料的依赖来改善环境</span><br />
                    <el-button @click="chanping()" type="danger">了解更多</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        chanping() {
            this.$router.push("/chanping");
        }
    },
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:rgb(239, 239, 239)");
    },
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.taiMain {
    width: 100%;
}

.taiOne h1 {
    font-size: 40px;
    color: #fff;
    position: absolute;
    top: 150px;
    left: 15%;
}

.taiTwo {
    width: 100%;
    height: 150px;
    text-align: center;
    padding: 60px 0 120px 0;
}

.taiTwo p {
    font-size: 20px;
    padding-bottom: 30px;
}

.taiTwo span {
    font-size: 12px;
    line-height: 30px;
}

.taiThree{
    width: 100%;
    position: relative;
}
.taiThree h3 {
    font-size: 25px;
    margin-bottom: 20px;
}

.taiThree span {
    font-size: 18px;
}

.taiThree .el-button {
    width: 120px;
    height: 40px;
    margin-top: 20px;
}

.tauThree-1 {
    position: absolute;
    right: 120px;
    top: 150px;
    text-align: right;
    
}

.tauThree-2 {
    position: absolute;
    top: 150px;
    left: 150px;
}

.tauThree-3 {
    position: absolute;
    top: 150px;
    right: 150px;
    text-align: right;
    color: #fff;
}
</style>