<template>
    <div class="guanMain">
        <img src="../../assets/guanyu/guanyu-1.jpg" width="100%">
        <div class="guanOne">
            <el-tabs v-model="activeName" @tab-click="handleClick" stretch="true" style="width: 40%;margin: 0 auto;">
                <el-tab-pane label="公司简介" name="one">
                    <div id="tab">
                        <p>{{ tbs1 }}</p>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="企业远景" name="two">
                    <div id="tab">
                        <p>{{ tbs2 }}</p>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="企业文化" name="three">
                    <div id="tab" class="tab-1">
                        <span style="color: black;">企业愿景：</span>为中国新能源屹立世界而努力!<br />
                        <span style="color: black;">企业使命：</span>优秀品质，厚德载福!<br />
                        <span style="color: black;">团队价值观：</span>诚信负责、追求不凡、合作共赢、感恩天下、团结一致、共创亿合。<br />
                        <span style="color: black;">工作理念：</span>比成功的更成功，比勤奋的更勤奋，比用心的更用心，比出色的更出色。<br />
                        <span style="color: black;">营销理念：</span>绝不为利益而推销产品，而是真心真诚的帮助别人。<br />
                        <span style="color: black;">服务理念：</span>以服务创造价值。<br />
                        <span style="color: black;">品牌推广：</span>专业成就品质，品质成就品牌。
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="guanTwo">
            <img src="../../assets/guanyu/guanyu-2.jpg" alt="" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeName: 'one',
            tbs1: '陕西中广金融科技有限公司，成立于2018年5月16日，坐落于繁华的国家中心城市一西安，是一家集金融信息服务;接受金融机构委托从事金融信息技术外包服务接受金融机构委托从务;接受金融机构委托从事金融知识流程外包服务;票据中介服务外包;接受金融机构委托从事贷款服务外包;代理金融机构应收账款的催告通知业务;投资管理;资产管理，网络贷款信息中介服务，智能化系统安全防范工程、建筑智能化工程、装饰装修工程、楼宇亮化工程的施工;数据处理;金融软件的开发;计算机软硬件、安防设备、智能银行设备、电气设备、电子产品、工艺品办公用品、日用百货、办公家具的销售营销活动策划;会议会展服务:数字印刷;设计、代理、制作、发布广告;高新技术开发为一体的综合创新服务公司',
            tbs2: '公司将致力于打造金融创新服务平台为发展目标，凭借与多家银行机构的战略合作，不断创新业务发展模式，在消费分期领域持续发力，联合更多合作伙伴，共同实现资源共享、合作共赢;同时，积极相应国家到2030年实现碳达峰、 2060年实现碳中和的发展纲要和新能源调整政策，利用自身绿色金融产品优势辅助，助力乡村振兴。'
        }
    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        }
    },
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:rgb(239, 239, 239)");
    },
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.guanMain {
    width: 100%;
}

.guanOne {
    padding-top: 35px;
}

#tab {
    height: 180px;
}

#tab p {
    font-size: 15px;
    text-indent: 38px;
    line-height: 25px;
}

.tab-1 {
    font-size: 15px;
    margin-left: 25px;
}

.tab-1 span {
    font-size: 15px;
    line-height: 25px;
}

.guanTwo {
    width: 100%;
    text-align: center;
    padding: 30px 0 40px 0;
}
</style>