<template>
    <div class="guanMain">
        <div class="guanOne">
            <h1>关于中广云分期</h1>
            <div style="width: 100%;height: 1px;background-color: rgb(182, 181, 181);margin-bottom: 20px;"></div>
            <p>中广云分期是陕西中广金融科技于2022年推出的一款通过网络线上APP实现我公司与中国工商银行代理的相关业务进行办理以及户 用光伏板材更替及安装选购的一款依托互联网为载体的软件。
                用户可以通过线上APP更加便利丰富的形式简化业务办理流程及户用电站的数据监测及方便快捷的故障报修服务等。</p>
            <div class="guanone-1">
                <p>1. 应用名称</p>
                <span>中广云分期</span>
            </div>
            <div class="guanone-1">
                <p>2. 应用版本</p>
                <span>1.23 beta版</span>
            </div>
            <div class="guanone-1">
                <p>3. 更新日期</p>
                <span>2022-08-27T06:39:14.000+0000</span>
            </div>
            <div class="guanone-1">
                <p>4. 开发者</p>
                <span>陕西中广金融科技有限公司</span>
            </div>
            <div class="guanone-1" style="margin-bottom: 30px;">
                <p>5. 产品特点</p>
                <span>
                    (a) 光伏、家装、车贷、现金分期实现线上一键提交申请，安全便捷，专业的银行对接服务。<br />
                    (b) 光伏电站发电实时数据观测分析，收益情况的分析,覆盖主流光伏品牌，精准图表走势、数据分析。<br />
                    (c) 自营商城提供各种板材耗材零配件销售。<br />
                    (d) 分期产品到期及时提醒，不逾期，及时获得还款提醒，轻松还款告别逾期。<br />
                    (a) 专业的故障保修团队，一键即可实现故障报修，精准定位。<br />
                    (a) 支持iPhone、Android平台
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    beforeDestroy() {
        document.querySelector("body").removeAttribute("style");
    },
    mounted() {
        document
            .querySelector("body")
            .setAttribute("style", "background-color:#FFF");
    },
}
</script>

<style scoped>
.guanMain {
    width: 100%;
    height: 100%;
    margin-top: 80px;
}

.guanOne {
    width: 80%;
    margin: 0 auto;
}

.guanOne h1 {
    font-size: 30px;
    margin-bottom: 30px;
}

.guanOne p {
    font-size: 15px;
    color: rgb(52, 52, 52);
}

.guanone-1 p {
    font-size: 20px;
    color: rgb(52, 52, 52);
    margin-top: 40px;
}

.guanone-1 span {
    font-size: 15px;
    color: rgb(52, 52, 52);
    line-height: 30px;
}
</style>