<template>
    <div class="sidMain">
        <div id="sidOne" @mouseover="sidseover1" @mouseleave="sidleave1">
            <img src="../assets/appXiaa.png" width="75%">
        </div>
        <div id="sidOne" @mouseover="sidseover2" @mouseleave="sidleave2">
            <img src="../assets/gongzonghao.png" width="75%" />
        </div>
        <div id="return_top" @click="toTop">
            <img src="../assets/fanTop.png" width="75%" />
        </div>
        <div id="sidTwo1" class="sidTwo">
            <img src="../assets/sid1.png" width="90%"><br />
            <span>扫码下载app</span>
        </div>
        <div id="sidTwo2" class="sidTwo">
            <img src="../assets/sid2.jpg" width="90%"><br />
            <span>扫码关注公众号</span>
        </div>
    </div>
</template>

<script>
import './sid'
export default {
    methods: {
        sidseover1() {
            let sid1 = document.getElementById('sidTwo1')
            sid1.style.display = 'block'
        },
        sidleave1() {
            let sid1 = document.getElementById('sidTwo1')
            sid1.style.display = 'none'
        },
        sidseover2() {
            let sid2 = document.getElementById('sidTwo2')
            sid2.style.display = 'block'
        },
        sidleave2() {
            let sid2 = document.getElementById('sidTwo2')
            sid2.style.display = 'none'
        },
        toTop() {
            document.documentElement.scrollTop = 0;
        },
    }
}
</script>

<style scoped>
* {
    margin: 0;
    padding: 0;
}

.sidMain {
    position: fixed;
    right: 10px;
    top: 385px;
    z-index: 999;
}

#sidOne {
    width: 40px;
    height: 40px;
    margin-top: 6px;
    border-radius: 3px;
    background-color: black;
    opacity: 0.4;
    text-align: center;
    line-height: 70px;
}

#sidOne:hover {
    background: red;
    opacity: 0.6;
}

#return_top {
    width: 40px;
    height: 40px;
    margin-top: 6px;
    border-radius: 3px;
    background-color: black;
    opacity: 0.4;
    text-align: center;
    line-height: 70px;
}

.sidTwo {
    width: 90px;
    height: 120px;
    background-color: #FFF;
    text-align: center;
}

.sidTwo img {
    margin-top: 8px;
}

.sidTwo span {
    font-size: 8px;
    line-height: 20px;
    color: rgb(10, 82, 216);

}

#sidTwo1 {
    position: fixed;
    right: 55px;
    top: 390px;
    display: none;

}

#sidTwo2 {
    position: fixed;
    right: 55px;
    top: 438px;
    display: none;
}
</style>