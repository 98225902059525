<template>
  <div>
    <go-top></go-top>
    <sidebar></sidebar>
    <body>
      <router-view />
    </body>
    <go-btn></go-btn>
  </div>
</template>

<script>
import goTop from './components/goTop.vue'
import GoBtn from './components/goBtn.vue';
import Sidebar from './components/sidebar.vue';
export default {
  components: { goTop, GoBtn, Sidebar },
  
}
</script>

<style>
html,body{
  font-size: 0px;
  margin: 0;
  padding: 0;
}
</style>